'use client';

import Link from 'next/link';
import { Button } from '@mui/material';
import theme from '@/theme';

import Logo from '@/components/Logo';
import styles from './Header.module.scss';
import { headerControlsHeight } from '@/constants';

const Header = () => (
  <header className='header-container'>
    <Link
      href='/'
      className={styles.link}
      aria-label='Get back to main page'
      prefetch={false}
    >
      <Logo />
    </Link>
    <Link
      href='/add-listing'
      aria-label='Go to listing'
      legacyBehavior
      passHref
      prefetch={false}
    >
      <Button
        href='/add-listing'
        variant='outlined'
        className={styles.addListing}
        sx={{
          height: headerControlsHeight,
          fontSize: '13px',
          [theme.breakpoints.up('sm')]: { width: 197, fontSize: '14px' },
        }}
      >
        Add Your Business
      </Button>
    </Link>
  </header>
);

export default Header;
