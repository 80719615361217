'use client';

import React, { useState } from 'react';
// @ts-ignore
import { Tabs, Tab } from 'react-tabs-scrollable'; // this library have problems with types
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'react-tabs-scrollable/dist/rts.css';

import Link from 'next/link';
import { Box } from '@mui/material';
import styles from './CategoryItems.module.scss';
import { handleGTMEvent } from '@/lib/utils/functions';
import { Category } from '@/lib/types/interfaces/Category';

const CategoryItems = ({ categories }: { categories: Category[] }) => {
  const [activeTab, setActiveTab] = useState(1);
  const onTabClick = (_e: Event, index: number) => {
    setActiveTab(index);
  };

  return (
    <div className={styles.wrapper}>
      {
        categories?.length ? (
          <Tabs
            activeTab={activeTab}
            leftNavBtnClassName={styles.navButtonLeft}
            rightNavBtnClassName={styles.navButtonRight}
            tabsContainerClassName={styles.tabsListContainer}
            rightBtnIcon={<ArrowForwardIosIcon />}
            leftBtnIcon={<ArrowBackIosIcon />}
            onTabClick={onTabClick}
            hideNavBtnsOnMobile={false}
          >
            {categories.map((item) => (
              <Tab
                key={`tab-item-${item.id}`}
                className={styles.tabItem}
              >
                <Box
                  onClick={() => handleGTMEvent(`/services/${item.slug}`, 'click_on_category_on_home_page')}
                >
                  <Link
                    href={`/services/${item.slug}`}
                    aria-label={`Go to ${item.slug}`}
                    prefetch={false}
                  >
                    {item.name}
                  </Link>
                </Box>
              </Tab>
            ))}
          </Tabs>
        ) : null
      }
    </div>
  );
};

export default CategoryItems;
