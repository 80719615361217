'use client';

import { Typography } from '@mui/material';
import Link from 'next/link';
import SearchForm from '@/components/SearchForm';

import styles from './SearchSection.module.scss';
import CategoryItems from '../../CategoryItems';
import theme from '@/theme';
import { Category } from '@/lib/types/interfaces/Category';

const SearchSection = ({ categories }: { categories: Category[] }) => (
  <div className={styles.wrapper}>
    <SearchForm />
    <CategoryItems categories={categories} />

    <Typography
      variant='h6'
      component='h2'
      sx={{ marginTop: 4 }}
    >
      Looking to expand your business?
    </Typography>
    <Link
      href='/add-listing'
      aria-label='Find customers'
      className={styles.addListingLink}
      prefetch={false}
    >
      <Typography
        variant='h6'
        sx={{ color: theme.palette.success.main }}
        maxWidth='fit-content'
      >
        Find Customers
      </Typography>
    </Link>
  </div>
);

export default SearchSection;
